import { render, staticRenderFns } from "./AutomationsDetailsTrigger.vue?vue&type=template&id=61eda3a6&scoped=true&"
import script from "./AutomationsDetailsTrigger.vue?vue&type=script&lang=js&"
export * from "./AutomationsDetailsTrigger.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61eda3a6",
  null
  
)

export default component.exports